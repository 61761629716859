.float-close-iframe {
  position: fixed !important;
  top: 2%;
  right: 1%;
  z-index: 9999999999999 !important;
  cursor: pointer !important;
  border-radius: 50% !important;
  background-color: #cfcfcf97 !important; 
  min-width: 18px !important;
  height: 32px !important;
  &:hover {
    background-color: #a0a0a073 !important; 
  }
}
