#meetingSDKElement {
  height: 600px;
}
.zmwebsdk-makeStyles-videoCustomize-8 {
    width: 60%;
}
.zmwebsdk-makeStyles-wrap-44, .zmwebsdk-makeStyles-wrap-49 {
    width: 100% !important;
}

@media (max-width: 420px) {
  #meetingSDKElement {
    height: 325px;
  }
  .zmwebsdk-makeStyles-videoCustomize-8 {
    width: 100%;
  }
}