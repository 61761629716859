.header-first-title {
  width: 50%;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-title-container {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background-color: #252525;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.header-buttons-container {
  padding: 0px 10px;
  display: flex;
  gap: 10px;
}

.header-btn-title {
  background-color: #0e7af7 !important;
  font-size: 11px !important;
  color: #fff !important;
  padding: 3px 10px !important;
  font-weight: 700;
}
