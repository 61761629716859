.header-create-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.start-meeting-tab {
  font-size: 12px !important;
  padding: 5px 10px;
  background: #dec567 !important;
  color: #412770 !important;
  font-weight: bold !important;
  &:hover {
    background: #dec97c !important;
    color: #604594 !important;
  }
}

.invite-meeting-tab {
  font-size: 12px !important;
  padding: 5px 10px;
  background: #412770 !important;
  color: #dec567 !important;
  font-weight: bold !important;
  &:hover {
    background: #604594 !important;
    color: #dec97c !important;
  }
}
