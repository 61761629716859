.presentation-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100% !important;
}

.css-1m9128y {
  display: none !important;
}

.presentation-sub-container {
  width: 50%;
  height: 50%;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.presentation-container .presentation-sub-container {
  width: 100% !important;
  height: 70% !important;
}

.presentation-sub-container {
  display: flex;
  gap: 10px;
}

.participant-view-presentation {
  width: 100vw !important;
  height: 100vh !important;
}