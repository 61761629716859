.presentation-container {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: hidden;
}

.css-1m9128y {
  display: none !important;
}
