main {
  width: 95%;
  margin: auto;
  text-align: center;
}

body {
  font-size: 12px !important;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.top-bar-container-fit {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.top-bar-container-fit-lite {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px 10% !important;
  align-items: center;
  background-color: #431275;
  color: #D6C177;
}

.content-final-step-share {
  height: 100%;
  width: 100%;
}

.logout-btn-lite {
  font-size: 16px !important;
  border-radius: 100px 100px 100px 100px !important;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: #dabe70 !important;
  color: #441176 !important;
  fill: #441176 !important;
  cursor: pointer !important;
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
  padding: 5px 15px !important;
}

.logout-btn-lite:hover {
  background-color: #441176 !important;
  color: #dabe70 !important;
  fill: #dabe70 !important;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.form-inline button {
  margin-top: 0px !important;
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.MuiStepLabel-label {
  font-size: 9px !important;
  font-weight: bold !important;
}

.css-83ijpv-MuiTypography-root {
  font-weight: bold !important;
}

.css-10hburv-MuiTypography-root {
  font-weight: bold !important;
}

.btn-personalized {
  margin-top: 20px;
  background-color: #dec567;
  color: #412770;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  border: none;
  outline: none;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 10px;
}

.btn-personalized:hover {
  background-color: #e4d18a;
  color: #614a8b;
}

.btn-personalized-skip {
  margin-top: 20px;
  background-color: #dec567;
  color: #412770;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  border: none;
  outline: none;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  border-radius: 1px solid #a8a8a8;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 10px;
}

.btn-personalized-skip:hover {
  background-color: #7d7d7d27;
}

.content-product-list {
  height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
}

.form-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.step-btn-content {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.btn-personalized:hover:active {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn-personalized:disabled {
  background-color: rgb(190, 190, 190);
  color: #8f72ae;
  box-shadow: 0;
}

#mui-1,
#mui-2 {
  font-size: 13px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.first-column {
  float: left;
  width: 63%;
  /* margin: 3px; */
}

.contentEarningDG {
  height: 100vh;
}

.menu-center-width {
  width: 100%;
}

circle {
  r: 5;
  background-color: "#dec567" !important;
}

.css-1m9128y {
  margin-top: 0px !important;
  text-align: end;
}

.css-1ps6pg7-MuiPaper-root {
  box-shadow: none !important;
}

.slider-content-sub {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 5px 10px 10px 10px;
}

.content-slider {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.sub-text-slider {
  padding-bottom: 5px;
  border-bottom: 1.5px solid rgb(195, 195, 195);
  text-align: left;
}

.hidden-scroll-bar::-webkit-scrollbar {
  width: 0em;
  background-color: #ffffff;
}

.btn-host-controls {
  background-color: #636363 !important;
  color: #fff !important;
  width: 100px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  display: block;
}

.btn-host-controls:hover {
  background-color: #969696 !important;
}

.btn-host-controls-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  width: 100% !important;
  height: 10% !important;
  justify-content: flex-end;
}

.btn-prev-carrusel-controls {
  background-color: #5c5c5c !important;
  color: #f6f6f6 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  width: 90px !important;
  height: 40px !important;
  padding: 10px !important;
}

.btn-prev-carrusel-controls:hover {
  background-color: #a0a0a0 !important;
  color: #c8c8c8 !important;
}

.btn-host-controls-participant {
  background-color: #ffcecb !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.btn-host-controls-participant:hover {
  background-color: #ffeae9 !important;
  color: #636363;
}

.hidden-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #b2b736;
}

.content-stats {
  height: 100%;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

[class*="zmwebsdk-makeStyles-inSharing-"] {
  overflow: visible !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] div {
  display: flex;
  justify-content: center;
  align-items: center;
}

[class*="zmwebsdk-makeStyles-inSharing-"] div canvas {
  width: 100% !important;
  height: auto !important;
}

#suspension-view-tabpanel-active
  div
  [class*="zmwebsdk-makeStyles-inSharing-"]:nth-child(2) {
  display: none !important;
}

.content-total {
  font-weight: bold;
  font-size: 26px;
}

.content-stats-text {
  line-height: 22px;
  font-size: 12px;
  border-left: 1.2px solid rgb(95, 95, 95);
  padding-left: 6px;
}

.take-off-border {
  border: none;
}

.MuiMenu-paper {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.second-column {
  float: right;
  width: 30%;
  height: 100%;
}

[class*="zmwebsdk-makeStyles-wrap-"] {
  height: 500px !important;
}

.zmwebsdk-makeStyles-wrap-375 {
  width: 100% !important;
}

.products-content::-webkit-scrollbar-thumb {
  background: #000 !important;
}

#suspension-view-tabpanel-active > div:first-child {
  height: 500px;
}

.products-content::-webkit-scrollbar {
  width: 0px !important;
}

.zmwebsdk-MuiPaper-root {
  width: 100% !important;
}

.container-entire-meeting {
  width: 100% !important;
  justify-content: space-between;
  margin: 0px !important;
}

.zmwebsdk-makeStyles-videoCustomize-8 {
  width: 65% !important;
}

.btnChoose-blue {
  background-color: #2e86c1 !important;
  border-radius: 0;
}

.btnChoose-black {
  background-color: black !important;
  border-radius: 0;
}

.btnChoose-black:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btnChoose-white {
  background-color: #fff !important;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
}

.btnChoose-white:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.content-btn-choose {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.card-product {
  cursor: pointer;
  width: 45%;
  padding: 15px;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 3px;
}

.card-product img {
  transition: transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.card-product:hover img {
  transform: scale(1.2);
}

.card-product-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.add-to-bag {
  background-color: rgba(178, 178, 178, 0);
  color: #000;
  border: 1px solid #000;
}

.add-to-bag-text {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  vertical-align: top;
  position: relative;
}

.add-to-bag-text::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #000;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
}

.add-to-bag-text:hover::before {
  background-color: #000;
  transform-origin: left;
  transform: scaleX(1);
  color: #000;
  text-decoration: none;
}

.buttons-container {
  display: flex;
  gap: 3px;
  height: 35px;
  width: 100%;
  justify-content: center;
}

.details {
  width: 35%;
  font-size: 12px;
  height: 100%;
  padding: 3px 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.add-bag {
  width: 65%;
  font-size: 12px;
  height: 100%;
  padding: 3px 5px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.card-price {
  font-weight: bold;
  text-align: initial;
}

.float {
  position: fixed;
  width: 200px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #fff;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  gap: 4px;
}

.float:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.bag-empty {
  color: #000;
  font-size: 15px;
  margin-bottom: 0px !important;
}

.content-bag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.total-color {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.overlay-base {
  z-index: 2000;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  overflow-y: auto;
  position: relative;
  border-radius: 5px;
  margin: 0 auto;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  height: 0%;
  width: 0%;
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  flex-direction: column;
}

.input-description {
  height: 10vh;
}

.content-after-max {
  width: 45%;
  height: 90% !important;
  background-color: #fff;
}

.main-container-meeting {
  width: 100% !important;
}

.content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}

.close-modal {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 100;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}

.text-close-modal {
  font-family: "HCo Gotham", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Ubuntu, sans-serif;
  color: #c9c9c9;
}

.text-close-modal:hover {
  color: #b0b0b0;
}

.content-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin-top: 10px;
}

.width-products {
  width: 175px;
}

.input-credentials {
  width: 100%;
  padding: 8px;
  border: 1px solid #cecece;
  border-radius: 3px;
  font-size: 14px;
}

.choose-card {
  width: 250px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
}

.choose-card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.choose-card-title {
  text-align: center;
  font-weight: bold;
}

.content-choose-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.image-header {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.choose-card-header {
  display: flex;
  justify-content: center;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 13px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 13px !important;
}

.new-text-area {
  word-break: break-word;
}

.container-input-create {
  width: 100%;
}

.content-after-max-invite {
  height: 72% !important;
  width: 45%;
  background: #fff;
  overflow: hidden;
}

.content-after-share {
  height: 75% !important;
  width: 60%;
  background: #fff;
  overflow: hidden;
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

.container-choose-copy {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.firstPart-copy {
  border: 0.7px solid #c9c9c9;
  width: 42%;
  height: 1px;
}

@media (max-width: 420px) {
  .choose-card {
    width: 250px;
  }

  .checkout-working-live {
    width: 100% !important;
  }

  .zmwebsdk-makeStyles-videoCustomize-8 {
    width: 95% !important;
  }

  .content-after-max {
    width: 100%;
    height: 95% !important;
  }
  .input-description {
    height: 14vh !important;
  }

  .input-credentials {
    width: 100%;
  }
  .products-content {
    height: 65vh;
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: initial;
  }
  .width-products {
    width: 160px;
  }
  .card-product {
    height: 384px;
  }
  .first-column {
    margin-left: 2%;
    width: 100%;
  }
  html,
  body {
    zoom: 100% !important;
  }

  .dashboard-container div:first-child {
    width: 100%;
  }

  .content {
    width: 100% !important;
  }
}

.mobile-version {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.share-meeting {
  border: none;
}

.MuiPickersPopper-root {
  z-index: 99999999999999 !important;
}

#CollectJSInlineccnumber html {
  padding: 0px;
}

#ccnumber input {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 3px !important;
}

#ccexp input {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 3px !important;
}

#cvv input {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 3px !important;
}

.container-payment-information {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.payment-form-container {
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.content-after-max-second {
  width: 70%;
  height: 80% !important;
  background-color: #fff;
}

.host-controls-content {
  background-color: #d9f1f7;
  padding: 10px;
  text-align: left;
  border-radius: 4px;
  width: 102%;
  margin-bottom: 10px;
}

.item-host-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 200px;
}

.item-host-controls-details {
  text-align: left;
}

.container-host-controls {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #f6f6f6;
}

.content-check-uncheck {
  background-color: #f6f6f6;
  width: 102%;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

@media screen and (max-width: 420px) {
  .dashboard-container {
    display: flex !important;
    flex-wrap: wrap !important;
    margin: 12px !important;
  }
  .dashboard-container-lite {
    display: flex !important;
    flex-wrap: wrap !important;
    margin: 12px !important;
  }

  .header-create-meeting {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 8px !important;
  }

  .tab-meetings-dashboard {
    width: 100% !important;
  }

  .content-slider {
    width: 100% !important;
  }
  .meeting-tab-options {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .header-detail-meeting {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }

  .dashboard-card-meeting {
    width: 100% !important;
  }

  .dashboard-card-meeting-container {
    width: 100% !important;
  }

  .right-side-cards {
    width: 100% !important;
  }

  .footer-main {
    width: 100% !important;
  }

  .content-after-max-second {
    width: 95%;
    height: 50% !important;
  }

  #suspension-view-tabpanel-active > div:first-child {
    height: 325px !important;
  }

  [class*="zmwebsdk-makeStyles-chatCustomize"] .zmwebsdk-MuiPaper-root {
    transform: translate(-26px, 150px) !important;
  }

  span[class*="zmwebsdk-MuiButton-iconSizeSmall"] svg {
    font-size: 25px !important;
  }

  div[role="dialog"][class*="zmwebsdk-makeStyles-chatCustomize"]
    div[class*="zmwebsdk-MuiPaper-root"] {
    transform: translate(-26px, 150px) !important;
  }

  [class*="zmwebsdk-makeStyles-wrap-"] {
    height: 325px !important;
  }

  [class*="zmwebsdk-makeStyles-chatCustomize"] {
    transform: translate3d(40px, 336px, 0px) !important;
  }

  [role="dialog"]
    > [class*="zmwebsdk-makeStyles-chatCustomize"]
    .zmwebsdk-MuiPaper-root {
    width: 113% !important;
    transform: translate(-25px, 145px) !important;
    height: 565px !important;
  }

  [class*="zmwebsdk-makeStyles-chatCustomize"] .zmwebsdk-MuiPaper-root {
    width: 113% !important;
    transform: translate(-25px, 145px) !important;
    height: 565px !important;
  }

  body {
    zoom: 84%;
  }

  .pro-sidebar {
    width: 305px !important;
  }

  .menu-header-layout {
    margin-bottom: 20px !important;
    padding: 9px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .content-after-share {
    height: 90% !important;
  }

  .credit-cards-container {
    justify-content: center;
  }

  .card-verify-email {
    width: 90% !important;
  }

  .form-content-first-box {
    width: 90% !important;
  }

  #suspension-view-tabpanel-active > div:first-child {
    height: 232px !important;
  }

  [class*="zmwebsdk-makeStyles-wrap-"] {
    height: auto !important;
  }
}

.header-title-zrp {
  background-color: #d9f1f7;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-presentation-btn {
  background-color: #404040 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.add-presentation-btn:hover {
  background-color: #636363 !important;
}

.card-zrp-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  width: 300px;
  height: 320px;
}

.container-zrp {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.open-presentation-zrp {
  font-size: 10px !important;
  font-weight: bold !important;
  background-color: #412770 !important;
  color: #dec567 !important;
}

.image-card-container-zrp {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-bottom: 0.4px solid rgb(134, 134, 134);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-zrp-container:hover {
  transform: scale(1.04);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.body-card-container-zrp {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.show-profile-img {
  border-radius: 50%;
}

.name-main-profile {
  padding: 10px;
}

.override-textfiled {
  font-size: 14px !important;
}

.note-content-presentation {
  padding: 10px;
}

.zrp-sub-btns {
  display: flex;
  gap: 10px;
}

.open-presentation-zrp:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: #cbcbcb !important;
}

.btn-zrp-reset {
  margin-left: 10px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.btn-zrp-reset:hover {
  background-color: #fff !important;
  color: rgb(108, 108, 108) !important;
}

.sub-slider-content {
  margin-top: 5px;
  display: flex;
  gap: 5px;
}

.zoom-setting-item {
  width: 100%;
  border-bottom: 1px solid #969696;
  margin-top: 10px;
}

.zoom-setting-subitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 10px;
}

.save-changes-btn {
  background-color: rgb(43, 43, 43) !important;
  color: #fff !important;
}

.save-changes-container {
  padding: 10px;
}

.subtitle-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.carrusel-container-dev {
  border-radius: 5px;
  border: 2px solid #000000;
  height: 71.5%;
  padding: 5px;
  overflow: hidden;
}

.container-carrusel-first-column {
  width: 58%;
}

.img-sub-slider-container {
  border: 1.5px solid #000;
  border-radius: 5px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notes-container-zrp {
  padding: 5px;
  overflow-y: scroll;
  width: 40% !important;
  height: 525px;
}

.slider-mobile-version {
  position: fixed;
  z-index: 999999999999;
  height: 100%;
  background: beige !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  left: -400px;
}

.slider-mobile-version-open {
  left: 0px !important;
}

.pro-sidebar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px -10px 8px;
}

.logo-wkl-navbar {
  padding: 5px;
}

.next-btn-payment {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.next-btn-payment-both {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.only-last-payment {
  width: 8%;
}

[class*="zmwebsdk-makeStyles-chatCustomize"] .zmwebsdk-MuiPaper-root {
  width: 110% !important;
  transform: translate(-60px, -7px) !important;
  height: 600px;
}

#transition-popper {
  z-index: 2 !important;
}

.carousel-container {
  width: 100%;
  height: 100%;
}

.carousel-container-live {
  width: 100%;
  height: 100%;
}

.recording-stats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  height: 94.3vh;
  overflow: hidden;
  overflow-y: scroll;
}

.topic-share-container {
  background-color: #dec567;
  margin-bottom: 10px;
  display: flex;
  padding: 10px 20px;
  width: 103%;
  justify-content: space-between;
  border-radius: 8px;
}

.recordings-items {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
}

.topic-recording-item {
  color: #428aca;
  cursor: pointer;
}

.topic-recording-item:hover {
  text-decoration: underline;
}

.number-detail-italic {
  color: #e64682;
  font-style: italic;
  font-size: 24px;
}

.body-zrp {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

[class*="zmwebsdk-makeStyles-inSharing-"] {
  overflow: visible !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] div {
  display: flex;
  justify-content: center;
  align-items: center;
}

[class*="zmwebsdk-makeStyles-inSharing-"] div canvas {
  width: 100% !important;
  height: auto !important;
}

#suspension-view-tabpanel-active
  div
  [class*="zmwebsdk-makeStyles-inSharing-"]:nth-child(2) {
  display: none !important;
}

.carrusel-container-dev:first-child {
  height: 102% !important;
}

.share-meeting-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}

.share-meeting-link-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.share-meeting-link-item:hover img {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.share-meeting-link-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.share-meeting-input-link {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.set-btn-size {
  padding: 16px !important;
  font-size: 11px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

#personalized-outlined-basic {
  font-weight: 300;
}

.content-credit-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

div[special="first"] {
  font-size: 12px !important;
}

div[special="first"] label {
  font-size: inherit;
}

div[special="first"] div {
  font-size: inherit;
}

.MuiTypography-root {
  font-family: inherit !important;
}

.cards-payment-container {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 40px !important;
  width: 100% !important;
}

.MuiButtonBase-root {
  font-family: "Source Sans Pro", sans-serif !important;
}

.only-next-payment {
  text-transform: capitalize !important;
}

@keyframes moveRigth {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.class-for-move {
  animation: moveRigth 1s forwards;
}

#ZOOM_WEB_SDK_SELF_VIDEO {
  top: -176px !important;
  left: 676px !important;
  width: 25% !important;
}

.verify-email-container {
  background-color: #fbf3ef;
  height: 76vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-verify-email {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 50px 100px;
  border-radius: 8px;
  background-color: #ffffff !important;
}

.container-verify-text {
  padding: 20px 0px 20px 0px;
}

.container-verify-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-meeting-tab:disabled {
  background-color: #dfcf94 !important;
  color: #765da5 !important;
}

.card-email-container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
  width: 70%;
  border-radius: 10px;
}

.enter-email-input {
  padding: 20px;
  border-radius: 30px;
  width: 70%;
}

.enter-email-input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.alert-container-email {
  position: fixed;
  width: 100%;
  z-index: 999999999999;
  top: 0;
  left: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.disabled-menu-item {
  color: #636363 !important;
  cursor: not-allowed;
}

.MuiDayPicker-header span {
  font-size: 9px !important;
}

/* MUI Date Pickers */

.MuiPickersPopper-paper
  div
  div[style*="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000;"] {
  display: none;
}

.MuiInputLabel-formControl {
  font-size: 12px !important;
}

#search-bar-custom-container div {
  width: 100%;
}

.select-dates-filter-container {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #dec567;
  padding: 16px 15px 10px 15px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: box-shadow 0.4s ease-in-out;
}

.select-dates-filter-container:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.MuiDialog-paperScrollPaper
  .MuiDialogContent-root
  div
  div[style*="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000;"] {
  display: none !important;
}

.select-dates-filter-container .MuiFormControl-root {
  width: auto !important;
}

div[role="dialog"][x-placement="right-start"] {
  z-index: 9999;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f72ae;
  opacity: 1; /* Firefox */
}

.container-user-credentials {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.react-search-box-dropdown {
  width: 40% !important;
  position: absolute;
  margin-top: 44px !important;
  z-index: 9999999999;
}

.react-search-box-dropdown-list-item {
  font-weight: bold;
}

.copy-my-size {
  padding: 10px 30px !important;
  font-size: 11px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.content-after-max-delete {
  height: 40% !important;
  width: 45%;
  background: #fff;
  overflow: hidden;
}
