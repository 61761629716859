.form-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbf3ef;
  gap: 20px;
  &-first-box {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  &-second-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-payment-title {
    padding: 20px 0px;
    text-align: center;
  }
}

.payment-container-page {
  background-color: #fbf3ef;
}

.payment-form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sell-twelve {
  width: 500px;
  height: 300px;
  filter: contrast(110%);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
