.btn-login-auth {
  border-radius: 4px !important;
  padding: 5px 18px 5px 18px !important;
  background: #b52560 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: inherit !important;
}

.btn-login-auth:hover {
  background: #b5256172 !important;
  color: rgb(194, 194, 194) !important;
}

.navbar-item-plan {
  cursor: pointer;
  font-family: "Manrope", Helvetica, sans-serif;
}

.navbar-item-plan:hover {
  color: rgb(194, 194, 194) !important;
}
