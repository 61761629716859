.dashboard-container {
  margin: 20px;
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  gap: 15px;
}

.dashboard-container-lite {
  margin: 20px;
  gap: 15px;
  height: 70vh;
}

.card-lite-container {
  display: flex;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  gap: 20px;
  flex-direction: column;
}

.hover-grow {
  transition: transform 0.3s; /* Agrega una transición suave para una animación más fluida */
}

.hover-grow:hover {
  transform: scale(
    1.1
  ); /* Cambia el tamaño del elemento cuando se hace hover */
}

.container-main-lite {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.card-lite-container:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.right-side-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tab-meetings-dashboard {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  border-radius: 5px;
}

.upcoming-meetings-container {
  height: 70vh !important;
  overflow: hidden;
  overflow-y: scroll;
}

.first-step-content {
  width: 100%;
  padding: 15px;
}

.select-products-container {
  width: 80% !important;
}

.select-info-meeting-container {
  width: 90% !important;
}

.review-meeting-container {
  padding: 20px 10px;
}

span[class*="MuiStepLabel-label Mui-completed"] {
  color: #412770 !important;
}

span[class*="MuiStepLabel-label Mui-active"] {
  color: #412770 !important;
}

.meeting-info-header {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 7px;
  padding: 10px;
}

.meeting-info-check {
  display: flex;
  align-items: center;
}

.images-info-container {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  gap: 10px;
  padding: 10px;
}

.images-info-container::-webkit-scrollbar {
  height: 5px !important;
}

.image-info-meeting {
  border-radius: 4px;
  border: 1px solid #dedede;
  cursor: pointer;
}

.shared-zoom-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  &-item {
    color: #73cce9;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #2e748b;
    }
  }
}

.content-form-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  &-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.shared-meeting-input {
  width: 65% !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.5px;
  border: 1px solid #ebd2da !important;
}

.btn-width-start {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
}

.btn-width-start-co {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  border: 1px solid #412770;
  color: #412770 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dededed2 !important;
    color: #6a6a6a !important;
  }
}

.btn-width-start-exit {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  background-color: #412770 !important;
  color: #dec567 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dedede !important;
    color: #2b2b2b !important;
  }
  &:disabled {
    background-color: #dedede !important;
    color: #686868 !important;
  }
}

.form-inline-container {
  height: 20vh;
  overflow: hidden;
  gap: 0px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dec567;
  }
}

.content-btn-down {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.btn-width-start-delete {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  background-color: #412770 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dedede !important;
    color: #2b2b2b !important;
  }
  &:disabled {
    background-color: #dedede !important;
    color: #686868 !important;
  }
}

.card-lite-btn {
  background-color: #d6c177 !important;
  color: #412770 !important;
  padding: 8px 15px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  width: 70% !important;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  }
  &:disabled {
    background-color: #bebebe !important;
    color: #7b7b7b !important;
  }
}

@media (max-width: 990px) {
  .dashboard-container-lite {
    height: auto !important;
  }
}
