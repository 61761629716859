.payment-values {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 0px !important;
  &-item {
    width: 45%;
  }
}
