.sub-content-zoom {
  width: 100%;
  height: auto;
  background-color: #fbf3f0 !important;
}

.content-card-plan {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &-box {
    background-color: #f8f8fa;
    padding: 8px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  &-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  &-buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px 15px 15px;
  }
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
}

.buy-btn-zoom {
  border-radius: 2px !important;
  border: 0px !important;
  font-size: 10px !important;
  font-weight: bold !important;
  padding: 8px 35px 8px 35px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  background-color: #b01d54 !important;
  color: #fff !important;
}

.buy-btn-zoom:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.change-bg-plan {
  background: linear-gradient(180deg, #b01d54 20%, #ffffff 0%);
}
