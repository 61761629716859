.dashboard-card-meeting-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  }
  &-header {
    padding: 0px 5px 10px 5px;
  }
  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.copy-share-btn {
  font-size: 14px !important;
  border: 1px solid #412770 !important;
  font-weight: 600 !important;
  &:hover {
    background: #bbbbbb !important;
    color: #404040 !important;
  }
}

.start-meeting-btn {
  font-size: 14px !important;
  background-color: #dec567 !important;
  color: #412770 !important;
  font-weight: 600 !important;
  &:hover {
    background: #dec97c !important;
    color: #604594 !important;
  }
  &:disabled {
    background-color: #dfcf94 !important;
    color: #765da5 !important;
    cursor: not-allowed !important;
  }
}
